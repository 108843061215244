@font-face {
  font-family: 'Apercu';
  src: local('Apercu'), url(./fonts/Apercu-Medium.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Apercu';
  src: local('Apercu'), url(./fonts/Apercu-Regular.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu';
  src: local('Apercu'), url(./fonts/Apercu-Italic.otf) format('opentype');
  font-weight: normal;
  font-style: italic;
}


body {
  margin: 0;
  font-family: 'Apercu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
}
h1 {
  font-family: 'Apercu', sans-serif;
  font-weight: bold;

  font-size: 48px;
}

h2 {
  font-family: 'Apercu', sans-serif;
  font-weight: bold;

  font-size: 36px;
}
h3 {
  font-family: 'Apercu', sans-serif;
  font-weight: bold;

  font-size: 30px;
}

h4 {
  font-family: 'Apercu';
  font-weight: bold;
  font-size: 26px;
}
h5 {
  font-family: 'Apercu';
  font-weight: normal;

  font-size: 22px;
}
h6 {
  font-family: 'Apercu';
  font-weight: normal;

  font-size: 19px;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
